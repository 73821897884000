import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  name: 'dev',
  apiUrl: 'http://10.11.10.129:7171/api',
  frontUrl: 'http://10.11.10.129:4200/',
  version: 'v1.0.0',
  grant_type: 'password',
  client_secret: 'Dn99elLOhv3IAjm1iyCFdxNQIPfA8Q6Y',
  client_id: 'scl-api',
  keyCloackAdminUrl: 'http://10.11.10.121:8080/admin/realms/SCL',
  kcUrl: 'http://10.11.10.121:8080/realms/SCL/protocol/openid-connect',
  cryptoKey: 'sclbo24',
};
