import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimMultiRepositoryBase } from '../../domain/repositories/claim-multi-base.repository';
import { ClaimMultiEntity } from '../../domain/entities/claim-multi.entity';
import { ClaimMultiRemoteDataSource } from '../data-source/claim-multi-remote.datasource';
import { ClaimsPaginationParams } from '../../domain/entities/claim-pagination-params.entity';
import { MultiPagination } from '../../domain/entities/multi-pagination.entity';

@Injectable()
export class ClaimMultiRepository extends ClaimMultiRepositoryBase {
  constructor(private claimRemote: ClaimMultiRemoteDataSource) {
    super();
  }

  override async createClaim(
    claim: ClaimMultiEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.createClaim(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async getMultiClaims(
    params: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure> {
    try {
      return await this.claimRemote.getMultiClaims(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }
}
