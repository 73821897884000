import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { Pagination } from 'src/app/core/interfaces/pagination.interface';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimFilterModel } from 'src/app/features/claims/data/models/claim-filter.model';
import { ClaimsPaginationParamsModel } from 'src/app/features/claims/data/models/claim-pagination-params.model';
import { UpdateClaimStatusModel } from 'src/app/features/claims/data/models/update-claim-status.model';
import { ClaimMultiEntity } from 'src/app/features/claims/domain/entities/claim-multi.entity';
import { ClaimPagination } from 'src/app/features/claims/domain/entities/claim-pagination.entity';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { GetMultiSingleClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-claim-multi-single.usecase';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetAllConciliatorsUseCase } from 'src/app/features/user/domain/usecases/get-all-conciliators.usecase';

@Component({
  selector: 'app-claims-multi-single-table',
  templateUrl: './claims-multi-single-table.component.html',
  styleUrls: ['./claims-multi-single-table.component.css'],
})
export class ClaimsMultiSingleTableComponent implements OnInit {
  public homeView: boolean = false;

  public pagination: Pagination = {
    totalQuantity: 0,
    numberOfPages: 0,
    itemsPerPage: 5,
    actualPage: 1,
  };
  paginatedData: ClaimPagination | undefined = undefined;

  public skeleton: boolean = false;
  public skeletons: any = [{}, {}, {}, {}, {}];

  public filters?: ClaimFilterModel;

  public userClaims?: any[];
  public selectedClaimId?: string;
  public claim?: ClaimEntity;

  public conciliators: UserData[] = [];
  public status: string[] = [
    ClaimStatus.PENDING,
    ClaimStatus.REFUSED,
    ClaimStatus.ADMITTED,
    ClaimStatus.AUDIENCE_SET,
    ClaimStatus.IN_INTERNAL_PROCESS,
    ClaimStatus.FINALIZED,
  ];
  public originalStatus?: string;

  newStatusClaimUpdate?: UpdateClaimStatusModel;

  audienciaDialogVisible: boolean = false;
  claimSelected?: ClaimMultiEntity;

  public rol?: string;
  public userId?: string;

  public items: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => {
        this.viewClaim();
      },
    },
  ];

  private AES = new AESEncryptDecryptService();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private getAllConciliatorsUseCase: GetAllConciliatorsUseCase,
    private localStorageService: LocalStorageServiceBase,
    private getMultiSingleClaimsUsecase: GetMultiSingleClaimsUseCase
  ) {}

  ngOnInit() {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    if (this.rol == 'Administrador') this.getAllConciliators();
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
    this.initClaims();
  }

  //CLAIMS
  async initClaims() {
    let params;
    if (this.rol == 'Administrador' || this.rol == 'Conciliador') {
      params = new ClaimsPaginationParamsModel(
        this.pagination.actualPage,
        this.pagination.itemsPerPage,
        undefined,
        this.filters,
        this.userId,
        undefined
      );
    } else {
      params = new ClaimsPaginationParamsModel(
        this.pagination.actualPage,
        this.pagination.itemsPerPage,
        undefined,
        this.filters,
        undefined,
        this.userId
      );
    }
    let result;
    result = await this.getMultiSingleClaimsUsecase.execute(params);
    if (result instanceof Failure) {
      this.skeleton = false;
      return;
    }
    if (result) {
      this.userClaims = result.response;
      this.pagination.totalQuantity = result.totalQuantity;
      setTimeout(() => {
        this.skeleton = false;
      }, 500);
    }
  }

  selectClaim(id: string) {
    this.selectedClaimId = id;
  }

  viewClaim(): void {
    if (this.selectedClaimId) {
      this.router.navigate(['/main/claims', this.selectedClaimId]);
    }
  }

  //AUDIENCE
  cancel(event: any) {
    this.claimSelected = undefined;
    this.audienciaDialogVisible = false;
  }
  createAudienceOk(event: any) {
    this.initClaims();
  }
  handleShowMessage(event: {
    severity: string;
    summary: string;
    detail: string;
  }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

  //CONCILIATOR
  async getAllConciliators(): Promise<void> {
    const result = await this.getAllConciliatorsUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    const noAssignedOption: UserData = {
      id: '',
      name: 'No Asignado',
      lastName: '',
      rol: '',
      cuil: '',
      gender: '',
      birthDate: new Date(),
      province: '',
      phone: '',
      email: '',
      civilStatus: '',
      city: '',
      street: '',
      number: '',
      floorApartment: '',
      postalCode: 0,
      company: '',
      companyCuil: '',
      dni: '',
      neighborhood: '',
      toJson: function (): Map<string, any> {
        throw new Error('Function not implemented.');
      },
    };
    this.conciliators = [noAssignedOption, ...result];
  }

  isConciliatorChangeAllowed(status: string): boolean {
    const allowedStatuses = ['Pendiente', 'Admitido'];
    return allowedStatuses.includes(status);
  }

  //STATE
  getStatusClass(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case 'Tramite Interno':
        return 'status-in-internal-process';
      case 'En trámite interno':
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set';
      case 'Audiencia fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  getStatusClassForPrincipalView(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft-principal';
      case ClaimStatus.PENDING:
        return 'status-pending-principal';
      case ClaimStatus.REFUSED:
        return 'status-refused-principal';
      case ClaimStatus.FINALIZED:
        return 'status-finalized-principal';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process-principal';
      case 'Tramite Interno':
        return 'status-in-internal-process-principal';
      case 'En trámite interno':
        return 'status-in-internal-process-principal';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set-principal';
      case 'Audiencia fijada':
        return 'status-audience-set-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  //TABLE
  changePage(event: any) {
    this.pagination.actualPage = event.page + 1;
    this.pagination.itemsPerPage = event.rows;
    this.skeleton = true;
    this.initClaims();
  }

  onFiltersApplied(filters: ClaimFilterModel) {
    this.filters = filters;
    this.pagination.actualPage = 1;
    this.skeleton = true;
    this.initClaims();
  }

  onFilterCancel(): void {
    this.pagination.actualPage = 1;
    this.filters = undefined;
    this.skeleton = true;
    this.initClaims();
  }
}
