import { Entity } from 'src/app/core/utils/entity';

export abstract class ClaimMultiEntity extends Entity {
  constructor(
    // Usuario
    public id: string,
    public userId?: string,
    public rol?: string,

    // Primer step: Datos de reclamante
    public razonSocial?: string,
    public companyCuit?: string,
    public companyEmail?: string,
    public companyPhone?: string,
    public streetCompany?: string,
    public numberCompany?: string,
    public floorApartmentCompany?: string,
    public towerCompany?: string,
    public postalCodeCompany?: string,
    public provinceCompany?: string,
    public cityCompany?: string,
    public claimantTypePerformance?: string,
    public departmentId?: string,

    // Representación de la empresa
    public lastName?: string,
    public name?: string,
    public cuil?: string,
    public claimantPosition?: string,
    public phone?: string,
    public email?: string,
    public birthday?: string,

    // Archivos
    public file?: string[],

    // Segundo step: Datos de reclamado
    public employees: EmployeesDto[] = [],

    // Tercer step: Detalle de reclamo
    public selecterObjectOfClaim?: string[],
    public textClaimDetails?: string,
    public extraDocumentationPdf?: string[],
    public selectedOwnRight?: string,
    public lawyerFullName?: string,
    public lawyerRegistrationNumber?: string,
    public lawyerEmail?: string,
    public consent?: boolean,

    // Datos de la entidad del reclamo
    public confirmInformation?: boolean,
    public startDate?: Date,
    public numberOfClaim?: string,
    public isBorrador: boolean = false
  ) {
    super();
  }
}

export abstract class EmployeesDto extends Entity {
  constructor(
    public nameClaimed?: string,
    public claimedCuil?: string,
    public claimedEmail?: string,
    public claimedStreet?: string,
    public claimedNumber?: string,
    public claimedFloorApartment?: string,
    public claimedTower?: string,
    public claimedPostalCode?: string,
    public claimedProvince?: string,
    public claimedCity?: string,
    public claimedDni?: string[],
  ) {
    super();
  }
}
