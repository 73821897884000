<p-toast />
<form
  [formGroup]="formClaimetDetails"
  (ngSubmit)="nextStep()"
  class="w-full relative mt-6"
>
  <section class="personalInformation">
    <span class="titleSection">Datos empresa</span>
    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Razón Social <span class="span">*</span></label
        >
        <input
          placeholder="Razón social"
          formControlName="claimetCompany"
          pInputText
          maxlength="50"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">CUIT <span class="span">*</span></label>
        <input
          pInputText
          placeholder="99-99999999-9"
          formControlName="claimetCompanyCuit"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetCompanyCuit', 'required')
          }"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electrónico <span class="span">*</span></label
        >
        <input
          placeholder="Correo electrónico"
          formControlName="company_email"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('company_email', 'required') ||
              hasErrors('company_email', 'maxlength') ||
              hasErrors('company_email', 'email')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('company_email', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('company_email', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono <span class="span">*</span></label>
        <input
          placeholder="Teléfono"
          formControlName="claimetCompanyPhone"
          pInputText
          maxlength="15"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Calle <span class="span">*</span></label>
        <input
          placeholder="Calle"
          formControlName="claimetStreet"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetStreet', 'required') ||
              hasErrors('claimetStreet', 'maxlength')
          }"
        />
        <div
          *ngIf="hasErrors('claimetStreet', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
        <div
          *ngIf="hasErrors('claimetStreet', 'maxlength')"
          class="text-danger p-error"
        >
          Máximo 50 caracteres permitidos
        </div>
      </div>
      <div class="col-12 md:col-2 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle">Número <span class="span">*</span></label>
        <input
          placeholder="Número"
          formControlName="claimetNumber"
          pInputText
          maxlength="5"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetNumber', 'required') ||
              hasErrors('claimetNumber', 'maxlength')
          }"
        />
        <div
          *ngIf="hasErrors('claimetNumber', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
        <div
          *ngIf="hasErrors('claimetNumber', 'maxlength')"
          class="text-danger p-error"
        >
          Máximo 5 caracteres permitidos
        </div>
      </div>
      <div class="col-12 md:col-2 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle">Piso/Depto</label>
        <input
          placeholder="Piso/Depto"
          formControlName="claimetFloorApartament"
          pInputText
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimetFloorApartament',
              'maxlength'
            )
          }"
        />
        <div
          *ngIf="hasErrors('claimetFloorApartament', 'maxlength')"
          class="text-danger p-error"
        >
          Máximo 10 caracteres permitidos
        </div>
      </div>
      <div class="col-12 md:col-2 lg:col-2 xl:col-2 sm:col-6">
        <label class="labelTitle">Torre</label>
        <input
          placeholder="Torre"
          formControlName="claimetTower"
          pInputText
          maxlength="30"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetTower', 'maxlength')
          }"
        />
        <div
          *ngIf="hasErrors('claimetTower', 'maxlength')"
          class="text-danger p-error"
        >
          Máximo 30 caracteres permitidos
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Código postal <span class="span">*</span></label
        >
        <input
          placeholder="Código postal"
          formControlName="claimetPostalCode"
          pInputText
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetPostalCode', 'required') ||
              hasErrors('claimetPostalCode', 'maxlength') ||
              hasErrors('claimetPostalCode', 'minlength') ||
              hasErrors('claimetPostalCode', 'pattern')
          }"
        />
        <div
          *ngIf="hasErrors('claimetPostalCode', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
        <div
          *ngIf="hasErrors('claimetPostalCode', 'maxlength')"
          class="text-danger p-error"
        >
          Máximo 10 caracteres permitidos
        </div>
        <div
          *ngIf="hasErrors('claimetPostalCode', 'minlength')"
          class="text-danger p-error"
        >
          Mínimo 4 caracteres requeridos
        </div>
        <div
          *ngIf="hasErrors('claimetPostalCode', 'pattern')"
          class="text-danger p-error"
        >
          Solo se permiten letras y números
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Provincia<span class="span">*</span></label>
        <p-dropdown
          id="claimetProvince"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione su Provincia"
          formControlName="claimetProvince"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetProvince', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localitiesClaimant"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="claimetCity"
        >
        </p-dropdown>
        <div
          *ngIf="hasErrors('claimetCity', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Departamento laboral<span class="span">*</span></label
        >
        <p-dropdown
          placeholder="Seleccione una opción"
          [options]="departments"
          optionLabel="name"
          optionValue="id"
          formControlName="department_id"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('department_id', 'required')
          }"
        />
        <div
          *ngIf="hasErrors('department_id', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Cargo<span class="span">*</span></label>
        <input
          [readOnly]="true"
          class="disabled"
          formControlName="claimetTypePerformance"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimetTypePerformance',
              'required'
            )
          }"
        />
        <div
          *ngIf="hasErrors('claimetTypePerformance', 'required')"
          class="text-danger p-error"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>
    <div class="flex align-items-center gap-2 mt-5">
      <span class="span">*</span>
      <p-checkbox
        (onChange)="onConsentChange($event)"
        [binary]="true"
        formControlName="consent"
        class="check-acceptsElectronicDomicile"
        label="El domicilio electrónico constituido a través de la indicación de un correo electrónico, se considerará a todos los efectos jurídicos como domicilio constituído, siendo válidas y vinculantes las notificaciones, emplazamientos, comunicaciones y citaciones que se practiquen en el mismo, gozando de plena validez y eficacia su constitución."
      />
    </div>
  </section>
  <p-divider></p-divider>
  <section class="employmentRelationshipDetails mt-3">
    <span class="titleSection">Datos personales</span>
    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Apellido/s <span class="span">*</span></label>
        <input
          [readOnly]="true"
          class="disabled"
          placeholder="Apellido/s"
          formControlName="claimetLastname"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetLastname', 'required') ||
              hasErrors('claimetLastname', 'maxlength') ||
              hasErrors('claimetLastname', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetLastname', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetLastname', 'maxlength')"
        >
          Este campo no puede superar los 50 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetLastname', 'pattern')"
        >
          El campo solo acepta letras.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Nombre/s <span class="span">*</span></label>
        <input
          placeholder="Nombre/s"
          formControlName="claimetName"
          [readOnly]="true"
          class="disabled"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetName', 'required') ||
              hasErrors('claimetName', 'maxlength') ||
              hasErrors('claimetName', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetName', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetName', 'maxlength')"
        >
          Este campo no puede superar los 50 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetName', 'pattern')"
        >
          El campo solo acepta letras.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Cuil <span class="span">*</span></label>
        <input
          pInputText
          [readOnly]="true"
          class="disabled"
          formControlName="claimetCuil"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetCuil', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetCuil', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Cargo que ocupa en la empresa <span class="span">*</span></label
        >
        <input
          placeholder="Cargo que ocupa en la empresa"
          formControlName="claimetPosition"
          [readOnly]="true"
          class="disabled"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetPosition', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPosition', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono <span class="span">*</span></label>
        <input
          formControlName="claimetPhone"
          [readOnly]="true"
          class="disabled"
          placeholder="Teléfono"
          pInputText
          maxlength="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetPhone', 'minlength') ||
              hasErrors('claimetPhone', 'maxlength') ||
              hasErrors('claimetPhone', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'pattern')"
        >
          El campo solo acepta números. Las letras o caracteres especiales no
          son permitidos.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetPhone', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electrónico <span class="span">*</span></label
        >
        <input
          placeholder="Correo electrónico"
          formControlName="claimetEmail"
          [readOnly]="true"
          class="disabled"
          type="text"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetEmail', 'required') ||
              hasErrors('claimetEmail', 'maxlength') ||
              hasErrors('claimetEmail', 'email')
          }"
        />

        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Provincia <span class="span">*</span> </label>
        <p-dropdown
          id="claimetProvinceUser"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione su Provincia"
          formControlName="claimetProvinceUser"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetProvinceUser', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetProvinceUser', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Localidad <span class="span">*</span></label>
        <p-dropdown
          [options]="localitiesUser"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="claimetLocalityUser"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetLocalityUser', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetLocalityUser', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Calle </label>
        <input
          placeholder="Calle"
          formControlName="claimetStreetUser"
          pInputText
          maxlength="50"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Número</label>
        <input
          placeholder="Número"
          formControlName="claimetNumberUser"
          pInputText
          maxlength="50"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Piso/Depto </label>
        <input
          placeholder="Piso/Depto"
          formControlName="claimetFloorApartmentUser"
          pInputText
          maxlength="50"
        />
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Código postal </label>
        <input
          placeholder="Código postal"
          formControlName="claimetPostalCodeUser"
          pInputText
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetPostalCode', 'minlength') ||
              hasErrors('claimetPostalCode', 'pattern')
          }"
        />
        <div
          *ngIf="hasErrors('claimetPostalCode', 'minlength')"
          class="text-danger p-error"
        >
          Mínimo 4 caracteres requeridos
        </div>
        <div
          *ngIf="hasErrors('claimetPostalCode', 'pattern')"
          class="text-danger p-error"
        >
          Solo se permiten letras y números
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"> Fecha de nacimiento</label>
        <p-calendar
          class="inputLarge"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          [maxDate]="maxDate"
          [minDate]="minDate"
          placeholder="DD/MM/AAAA"
          dateFormat="dd/mm/yy"
          formControlName="claimetBirthDateUser"
        ></p-calendar>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetBirthDateUser', 'underage')"
        >
          Debes tener al menos 18 años
        </div>
      </div>
    </div>
  </section>
  <p-divider></p-divider>
  <section class="attachedFiles mt-3">
    <span class="titleSection">Archivos adjuntos</span>
    <div class="grid w-full mt-3">
      <div class="col-12">
        <label class="labelTitle">Documentos<span class="span">*</span></label>
        <p class="descriptionContent mb-0">
          Cargue el documento que acredite la representación que invoca.
        </p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12 '
              : 'dropzone-container h-6rem col-12 '
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event, 'receipt')"
        >
          <input
            #inputFilePfd
            type="file"
            multiple="false"
            (change)="onChange($event, 'receipt')"
            accept=".pdf, .jpg, .png"
          />

          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFilePfd.click()"
              #upload
              class="btnUploadFile mr-1"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent"
          >Archivos permitidos: JPG / JPEG / WEBP / HEIF / PDF / PNG
        </span>
      </div>
      <div class="col-12 md:col-4 lg:col-4 xl:col-6">
        <div
          class="text-danger p-error"
          *ngIf="
            formClaimetDetails
              .get('claimetFilePaycheck')
              ?.hasError('fileCount') &&
            formClaimetDetails.get('claimetFilePaycheck')?.touched
          "
        >
          Se deben subir entre 1 y 12 archivos.
        </div>
        <div
          class="conteiner-UploadRight-pdf"
          *ngFor="let pdf of pdfFiles; let i = index"
        >
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span class="descriptionContent">{{ pdf.name }}</span>
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (onClick)="deleteFilePfd(i)"
          ></p-button>
        </div>
      </div>
    </div>
  </section>
  <div class="grid w-full flex justify-content-end">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        type="submit"
        [disabled]="formClaimetDetails.invalid"
      ></p-button>
    </div>
  </div>
</form>
