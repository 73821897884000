import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from 'src/app/core/components/primeng/primeng.module';
import { MainRoutingModule } from './main-routing.module';
import { MainBaseComponent } from './presentation/pages/main-base/main-base.component';
import { MyLaborClaims } from './presentation/components/myLaborClaims/myLaborClaims.component';
import { SharedModule } from 'src/app/core/shared.module';
import { HomeComponent } from './presentation/components/home/home.component';
import {
  UserRemoteDataSourceBase,
  UserRemoteDataSource,
} from '../user/data/data-sources/user-remote.datasource';
import { UserRepositoryBase } from '../user/domain/repositories/user-base.repository';
import { UserRepository } from '../user/data/repositories/user.repository';
import { GetMyDataUseCase } from '../user/domain/usecases/get-my-data.usecase';
import {
  ClaimRemoteDataSource,
  ClaimRemoteDataSourceBase,
} from '../claims/data/data-source/claim-remote.datasource';
import { ClaimRepositoryBase } from '../claims/domain/repositories/claim-base.repository';
import { ClaimRepository } from '../claims/data/repositories/claim.repository';
import { GetPaginatedClaimsUseCase } from '../claims/domain/usecases/get-paginated-claims.usecase';
import { ConfigurationComponent } from '../user/presentation/pages/configuration/configuration.component';
import { ResetPasswordComponent } from '../user/presentation/components/reset-password/reset-password.component';
import { ClaimViewComponent } from '../claims/presentation/claims/views/claim-view.component';
import { ClaimUseCase } from '../claims/domain/usecases/get-claim.usecase';
import { UpdateClaimUseCase } from '../claims/domain/usecases/update-claim.usecase';
import { DeleteClaimUseCase } from '../claims/domain/usecases/delete-claim.usecase';
import { CreateClaimUseCase } from '../claims/domain/usecases/create-claim.usecase';
import {
  AudienceRemoteDataSource,
  AudienceRemoteDataSourceBase,
} from '../audiences/data/data-source/audience-remote.datasource';
import { AudienceRepositoryBase } from '../audiences/domain/repositories/aucience-base.repository';
import { AudienceRepository } from '../audiences/data/repositories/audience.repository';
import { AudienceUseCase } from '../audiences/domain/usecases/get-audience.usecase';
import { AudienceTableComponent } from '../audiences/presentation/components/audience-table/audience-table.component';
import { GetPaginationAudiencesUseCase } from '../audiences/domain/usecases/get-pagination-audiences.usecase';
import { CreateAudienceUseCase } from '../audiences/domain/usecases/create-audience.usecase';
import { DeleteAudienceUseCase } from '../audiences/domain/usecases/delete-audience.usecase';
import { UpdateAudienceUseCase } from '../audiences/domain/usecases/update-audience.usecase';
import { ComisionHomeComponent } from './presentation/components/home/comision-home/comision-home.component';
import { AudiencesComponent } from './presentation/components/audiences/audiences.component';
import { SimpleRolHomeComponent } from './presentation/components/home/simple-rol-home/simple-rol-home.component';
import { GetPaginatedAllClaimsUseCase } from '../claims/domain/usecases/get-pagination-all-claims.usecase';
import { GetFileUseCase } from '../claims/domain/usecases/get-file.usecase';
import { GetPendingQuantityClaimsUseCase } from '../claims/domain/usecases/get-pending-quantity-claims.usecase';
import { GetAllConciliatorsUseCase } from '../user/domain/usecases/get-all-conciliators.usecase';
import { GetColectiveClaimsUseCase } from '../claims/domain/usecases/get-colective-claim.usercase';
import { UpdateClaimStatusUseCase } from '../claims/domain/usecases/update-claim-status.usecase';
import { UpdateClaimConciliatorUseCase } from '../claims/domain/usecases/update-claim-conciliator.usecase';
import { GetNextAudienceOfConciliatorUseCase } from '../audiences/domain/usecases/get-next-audience-of-conciliator.usecase';
import { GetNextSetAudienceUseCase } from '../audiences/domain/usecases/get-next-set-audience.usecase';
import { ClaimStatusDisplayPipe } from 'src/app/core/pipe/claim-status-display.pipe';
import { GetPaginatedAllClaimsByConciliatorUseCase } from '../claims/domain/usecases/get-pagination-all-cliams-by-conciliator.usecase';
import { SuperAdminHomeComponent } from './presentation/components/home/SuperAdmin-home/SuperAdmin-home.component';
import { UsersComponent } from './presentation/components/users/users.component';
import { CompaniesComponent } from './presentation/components/companies/companies.component';
import { UsersTableComponent } from '../user/presentation/components/User-SuperAdmin/users-table.component';
import { GetPaginatedUserUseCase } from '../user/domain/usecases/get-paginated-user.usecase';
import { DeleteUserUseCase } from '../user/domain/usecases/delete-user.usecase';
import { UserUseCase } from '../user/domain/usecases/get-user.usecase';
import { GetPaginatedAllUserUseCase } from '../user/domain/usecases/get-pagination-all-user.usecase';
import { GetPaginationAudiencesofConciliatorUseCase } from '../audiences/domain/usecases/get-pagination-Conciliator-audiences.usecase';
import { newUserComponent } from '../user/presentation/components/New-User-SuperAdmin/newUser.component';
import { createUsersComponent } from './presentation/components/users/newUser/createUsers.component';
import { AudienceComponent } from '../audiences/presentation/components/audience/audience.component';
import { CreateComisionUserUseCase } from '../user/domain/usecases/create-user.usecase';
import { UpdateUserUseCase } from '../user/domain/usecases/update-user.usecase';
import {
  CompanyRemoteDataSource,
  CompanyRemoteDataSourceBase,
} from '../company/data/data-source/company-remote.datasource';
import { CompanyrepositoryBase } from '../company/domain/repositories/company-base.repository';
import { CompanyRepository } from '../company/data/repositories/company.repository';
import { CompanyTableComponent } from '../company/presentation/components/company-table/company-table.component';
import { IndividualCompanyComponent } from '../company/presentation/components/individual-company/individual-company.component';
import { CreateCompanyUseCase } from '../company/domain/usecases/create-company.usecase';
import { GetAllPaginationCompaniesUseCase } from '../company/domain/usecases/get-all-pagination-companies.usecase';
import { GetCompanyUseCase } from '../company/domain/usecases/get-company.usecase';
import { UpdateCompanyUseCase } from '../company/domain/usecases/update-company.usecase';
import { DeleteCompanyUseCase } from '../company/domain/usecases/delete-company.usecase';
import { MultiCompanyComponent } from '../company/presentation/components/multi-company/multi-company.component';
import { CreateMultiCompanyUseCase } from '../company/domain/usecases/create-multi-company.usecase';
import { SafeResourceUrlPipe } from 'src/app/core/pipe/safe-pdf.pipe';
import { MinuteComponent } from '../minutes/presentation/components/minute/minute.component';
import {
  MinuteRemoteDataSource,
  MinuteRemoteDataSourceBase,
} from '../minutes/data/data-source/minute-remote.datasource';
import { MinuteRepositoryBase } from '../minutes/domain/repositories/minute-base.repository';
import { MinuteRepository } from '../minutes/data/repositories/minute.repository';
import { CreateMinuteUseCase } from '../minutes/domain/usecases/create-minute.usecase';
import { GetMinutesByClaimIdUseCase } from '../minutes/domain/usecases/get-minutes-by-claim-id.usecase';
import { AudienceTableComponentNext } from '../audiences/presentation/components/audience-table-next/audience-table-next.component';
import { SidebarModule } from 'primeng/sidebar';
import { NavComponent } from '../../core/components/nav/nav.component';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { CardModule } from 'primeng/card';
import { MegaMenuModule } from 'primeng/megamenu';
import { GetNextAudienceOfClaimantUseCase } from '../audiences/domain/usecases/get-next-audience-of-claimant.usecase';
import { GetAllAudiencesByIdConciliadorPagination } from '../audiences/domain/usecases/getAllAudiencesByIdConciliadorPagination.usecase';
import { GetNextHomeAudienceOfConciliatorUseCase } from '../audiences/domain/usecases/get-next-audience-home-of-conciliator.usecase';
import { RippleModule } from 'primeng/ripple';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { GetFileBaseUseCase } from '../claims/domain/usecases/get-file-base.usecase';
import { MultiSelectModule } from 'primeng/multiselect';
import { GetAllNextAudiencesCase } from '../audiences/domain/usecases/get-all-next-audiences.usecase';
import { UpdateUserConfigComponent } from '../user/presentation/components/Update-User-Config/update-user-config.component';
import { UpdateUserAdminUseCase } from '../user/domain/usecases/update-user-admin.usecase';
import { ClaimantDetailsEmployerComponent } from '../claims/presentation/claims/add/claim-employer/claim-single/claimant-details-employer/claimant-details-employer.component';
import { ActionButtonsComponent } from '../claims/presentation/claims/views/components/action-buttons/action-buttons.component';
import { ClaimViewEmployerComponent } from '../claims/presentation/claims/views/claim-view-employer/claim-view-employer.component';
import { ClaimViewWorkerComponent } from '../claims/presentation/claims/views/claim-view-worker/claim-view-worker.component';
import { UserIdUseCase } from '../user/domain/usecases/get-user-id.usecase';
import { ClaimTypesComponent } from '../claims/presentation/claims/components/claim-types/claim-types.component';
import { ClaimantDetailsColectiveComponent } from '../claims/presentation/claims/add/claim-employer/claim-collective/claimant-details-colective/claimant-details-colective.component';
import { ClaimedDetailsColectiveComponent } from '../claims/presentation/claims/add/claim-employer/claim-collective/claimed-details-colective/claimed-details-colective.component';
import { ClaimDetailsColectiveComponent } from '../claims/presentation/claims/add/claim-employer/claim-collective/claim-details-colective/claim-details-colective.component';
import { CreateClaimColectiveUseCase } from '../claims/domain/usecases/create-cliam-colective.usecase';
import { ClaimColectiveRepositoryBase } from '../claims/domain/repositories/cliam-colective-base.repository';
import { ClaimColectiveRepository } from '../claims/data/repositories/cliam-colective.repository';
import { ClaimColectiveRemoteDataSource } from '../claims/data/data-source/cliam-colective-remote.datasource';
import { AudienceFilterComponent } from '../audiences/presentation/components/audience-filter/audience-filter.component';
import { ClaimsColectiveTableComponent } from '../claims/presentation/claims/components/claims-tables/components/claims-colective-table/claims-colective-table.component';
import { NumberOnlyNoLengthDirective } from 'src/app/core/utils/numberOnlyDirective';
import { UpdateCollectiveClaimStatusUseCase } from '../claims/domain/usecases/update-collective-status.usecase';
import { UpdateClaimColectiveConciliatorUseCase } from '../claims/domain/usecases/update-claim-colective-conciliator.usecase';
import { ClaimColectiveUseCase } from '../claims/domain/usecases/get-claim-colective-usecase';
import { UpdateClaimColectiveUseCase } from '../claims/domain/usecases/update-claim-colective.usecase';
import { GetFileColectiveUseCase } from '../claims/domain/usecases/get-file-colective.usecase';
import { ClaimComponent } from '../claims/presentation/claims/add/claim-worker/claim.component';
import { ClaimantDetailsFormComponent } from '../claims/presentation/claims/add/claim-worker/claimant-details-form/claimant-details-form.component';
import { ClaimedDetailsFormComponent } from '../claims/presentation/claims/add/claim-worker/claimed-details-form/claimed-details-form.component';
import { ClaimDetailsFormComponent } from '../claims/presentation/claims/add/claim-worker/claim-details-form/claim-details-form.component';
import { ClaimEmployerComponent } from '../claims/presentation/claims/add/claim-employer/claim-single/claim-employer.component';
import { ClaimedDetailsEmployerComponent } from '../claims/presentation/claims/add/claim-employer/claim-single/claimed-details-employer/claimed-details-employer.component';
import { ClaimColectiveComponent } from '../claims/presentation/claims/add/claim-employer/claim-collective/claim-colective.component';
import { ClaimsTableComponent } from '../claims/presentation/claims/components/claims-tables/claims-table/claims-table.component';
import { ClaimsComisionTableComponent } from '../claims/presentation/claims/components/claims-tables/claims-comision-table/claims-comision-table.component';
import { ClaimFilterComponent } from '../claims/presentation/claims/components/claim-filter/claim-filter.component';
import { DeleteCollectiveClaimUseCase } from '../claims/domain/usecases/delete-collective-claim';
import { MeetLinkExtractorDirective } from 'src/app/core/utils/meetDerective';
import { ClaimViewColectiveComponent } from '../claims/presentation/claims/views/claim-view-colective/claim-view-colective.component';
import { GetCompanyCuilUseCase } from '../company/domain/usecases/get-company-colective.usecase';
import { ClaimMultiSingleComponent } from '../claims/presentation/claims/add/claim-employer/claim-multi-single/claim-multi-single.component';
import { ClaimedDetailsMultiComponent } from '../claims/presentation/claims/add/claim-employer/claim-multi-single/claimed-details-multi/claimed-details-multi.component';
import { ClaimantDetailsMultiComponent } from '../claims/presentation/claims/add/claim-employer/claim-multi-single/claimant-details-multi/claimant-details-multi.component';
import { TruncatePipe } from 'src/app/core/pipe/truncate.pipe';

import { AccordionModule } from 'primeng/accordion';
import { CreateClaimMultiUseCase } from '../claims/domain/usecases/create-claim-multi.usecase';
import { ClaimMultiRepository } from '../claims/data/repositories/claim-multi.repository';
import { ClaimMultiRepositoryBase } from '../claims/domain/repositories/claim-multi-base.repository';
import { ClaimMultiRemoteDataSource } from '../claims/data/data-source/claim-multi-remote.datasource';
import { ClaimsMultiSingleTableComponent } from '../claims/presentation/claims/components/claims-tables/components/claims-multi-single-table/claims-multi-single-table.component';
import { GetMultiSingleClaimsUseCase } from '../claims/domain/usecases/get-claim-multi-single.usecase';
@NgModule({
  declarations: [
    MainBaseComponent,
    HomeComponent,
    MyLaborClaims,
    ClaimsTableComponent,
    ConfigurationComponent,
    ResetPasswordComponent,
    ClaimViewComponent,
    ClaimComponent,
    ClaimantDetailsFormComponent,
    ClaimedDetailsFormComponent,
    ClaimDetailsFormComponent,
    AudienceTableComponent,
    ClaimsComisionTableComponent,
    ComisionHomeComponent,
    AudiencesComponent,
    SimpleRolHomeComponent,
    ClaimStatusDisplayPipe,
    SuperAdminHomeComponent,
    UsersComponent,
    CompaniesComponent,
    UsersTableComponent,
    newUserComponent,
    createUsersComponent,
    AudienceComponent,
    CompanyTableComponent,
    IndividualCompanyComponent,
    MultiCompanyComponent,
    SafeResourceUrlPipe,
    TruncatePipe,
    MinuteComponent,
    AudienceTableComponentNext,
    NavComponent,
    UpdateUserConfigComponent,
    ClaimFilterComponent,
    AudienceFilterComponent,
    ClaimEmployerComponent,
    ClaimedDetailsEmployerComponent,
    ClaimantDetailsEmployerComponent,
    ActionButtonsComponent,
    ClaimViewEmployerComponent,
    ClaimViewWorkerComponent,
    ClaimTypesComponent,
    ClaimsColectiveTableComponent,
    ClaimColectiveComponent,
    ClaimantDetailsColectiveComponent,
    ClaimedDetailsColectiveComponent,
    ClaimDetailsColectiveComponent,
    NumberOnlyNoLengthDirective,
    MeetLinkExtractorDirective,
    ClaimViewColectiveComponent,
    ClaimMultiSingleComponent,
    ClaimantDetailsMultiComponent,
    ClaimedDetailsMultiComponent,
    ClaimsMultiSingleTableComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimengModule,
    MainRoutingModule,
    SharedModule,
    FormsModule,
    SidebarModule,
    AvatarModule,
    MenubarModule,
    CardModule,
    MegaMenuModule,
    RippleModule,
    InputIconModule,
    IconFieldModule,
    MultiSelectModule,
    AccordionModule,
  ],
  providers: [
    { provide: UserRemoteDataSourceBase, useClass: UserRemoteDataSource },
    { provide: UserRepositoryBase, useClass: UserRepository },
    GetMyDataUseCase,
    GetAllConciliatorsUseCase,
    GetColectiveClaimsUseCase,
    UpdateCollectiveClaimStatusUseCase,
    DeleteCollectiveClaimUseCase,
    CreateComisionUserUseCase,
    UpdateUserUseCase,
    UpdateUserAdminUseCase,
    { provide: ClaimRemoteDataSourceBase, useClass: ClaimRemoteDataSource },
    {
      provide: ClaimColectiveRemoteDataSource,
      useClass: ClaimColectiveRemoteDataSource,
    },
    { provide: ClaimRepositoryBase, useClass: ClaimRepository },
    {
      provide: ClaimColectiveRepositoryBase,
      useClass: ClaimColectiveRepository,
    },
    {
      provide: ClaimMultiRemoteDataSource,
      useClass: ClaimMultiRemoteDataSource,
    },
    {
      provide: ClaimMultiRepositoryBase,
      useClass: ClaimMultiRepository,
    },
    GetPaginatedClaimsUseCase,
    ClaimUseCase,
    UpdateClaimUseCase,
    UpdateClaimColectiveUseCase,
    DeleteClaimUseCase,
    CreateClaimUseCase,
    GetFileUseCase,
    GetFileBaseUseCase,
    GetFileColectiveUseCase,
    GetPaginatedAllClaimsUseCase,
    GetPendingQuantityClaimsUseCase,
    UpdateClaimStatusUseCase,
    UpdateCollectiveClaimStatusUseCase,
    UpdateClaimConciliatorUseCase,
    UpdateClaimColectiveConciliatorUseCase,
    GetPaginatedAllClaimsByConciliatorUseCase,
    {
      provide: AudienceRemoteDataSourceBase,
      useClass: AudienceRemoteDataSource,
    },
    { provide: AudienceRepositoryBase, useClass: AudienceRepository },
    AudienceUseCase,
    GetPaginationAudiencesUseCase,
    CreateAudienceUseCase,
    DeleteAudienceUseCase,
    GetAllNextAudiencesCase,
    UpdateAudienceUseCase,
    GetNextAudienceOfConciliatorUseCase,
    GetNextAudienceOfClaimantUseCase,
    GetNextSetAudienceUseCase,
    GetPaginatedUserUseCase,
    DeleteUserUseCase,
    UserUseCase,
    GetPaginatedAllUserUseCase,
    GetPaginationAudiencesofConciliatorUseCase,
    { provide: CompanyRemoteDataSourceBase, useClass: CompanyRemoteDataSource },
    { provide: CompanyrepositoryBase, useClass: CompanyRepository },
    CreateCompanyUseCase,
    GetAllPaginationCompaniesUseCase,
    GetCompanyUseCase,
    GetCompanyCuilUseCase,
    UpdateCompanyUseCase,
    DeleteCompanyUseCase,
    CreateMultiCompanyUseCase,
    { provide: MinuteRemoteDataSourceBase, useClass: MinuteRemoteDataSource },
    { provide: MinuteRepositoryBase, useClass: MinuteRepository },
    CreateMinuteUseCase,
    GetMinutesByClaimIdUseCase,
    GetAllAudiencesByIdConciliadorPagination,
    GetNextHomeAudienceOfConciliatorUseCase,
    UserIdUseCase,
    CreateClaimColectiveUseCase,
    ClaimColectiveUseCase,
    CreateClaimMultiUseCase,
    GetMultiSingleClaimsUseCase,
  ],
})
export class MainModule {}
