<p-table
  [tableStyle]="{ 'min-width': '100%' }"
  [rowHover]="true"
  [value]="skeletons"
  *ngIf="skeleton"
>
  <ng-template pTemplate="header">
    <tr class="font-bold text-xs sm:text-md">
      <th style="width: 20%">Reclamante</th>
      <th style="width: 20%">Reclamado</th>
      <th style="width: 15%">Nº de reclamo</th>
      <th style="width: 15%">Nº de expte.</th>
      <th style="width: 15%">Conciliador</th>
      <th style="width: 15%">Estado</th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-skeleton>
    <tr>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
      <td class="text-center"><p-skeleton></p-skeleton></td>
    </tr>
  </ng-template>
</p-table>

<p-table
  *ngIf="!skeleton"
  [value]="userClaims!"
  [tableStyle]="{ 'min-width': '100%' }"
>
  <ng-template pTemplate="caption" class="col-12" *ngIf="!homeView">
    <app-claim-filter
      class="modal-content"
      (filters)="onFiltersApplied($event)"
      (cancel)="onFilterCancel()"
    ></app-claim-filter>
  </ng-template>

  <ng-template pTemplate="header">
    <tr class="font-bold text-xs sm:text-md">
      <th class="text-left" style="width: 20%">Reclamante</th>
      <th class="text-left" style="width: 20%">Reclamado</th>
      <th class="text-center" style="width: 10%">Nº de reclamo</th>
      <th class="text-center" style="width: 10%">Nº de expte.</th>
      <th class="text-center" style="width: 20%">Conciliador</th>
      <th class="text-center" style="width: 15%">Estado</th>
      <th style="width: 5%"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-claim>
    <tr class="table-row text-xs sm:text-sm">
      <td class="text-left">
        {{ claim.razon_social ? (claim.razon_social | truncate) : "-" }}
      </td>
      <td class="text-left">Cantidad de reclamados: -</td>
      <td class="text-center">
        {{ claim.number_of_claim ? claim.number_of_claim : "-" }}
      </td>
      <td class="text-center">
        {{ claim.expediente_claim ? claim.expediente_claim : "-" }}
      </td>
      <td
        [ngClass]="this.rol !== 'Administrador' ? 'text-center' : 'text-center'"
        *ngIf="this.rol !== 'Administrador'"
      >
        {{ claim.start_date ? (claim.start_date | date : "dd/MM/yyyy") : "-" }}
      </td>
      <td
        class="text-center"
        style="width: 10%"
        *ngIf="this.rol == 'Administrador'"
      >
        <!-- (onChange)="updateConciliatorCheck($event)" -->
        <p-dropdown
          [options]="conciliators"
          class="conciliator"
          appendTo="body"
          [placeholder]="
            claim.conciliator_full_name
              ? claim.conciliator_full_name
              : 'No asignado'
          "
          [disabled]="!isConciliatorChangeAllowed(claim.status)"
          emptyMessage="No se encontraron resultados"
          emptyFilterMessage="No se encontraron resultados"
        >
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <span style="text-align: end">
              {{
                selectedOption.name + " " + selectedOption.last_name | truncate
              }}
            </span>
          </ng-template>
          <ng-template let-state pTemplate="item">
            <span [ngClass]="{ 'no-assigned-option': !state.id }">
              {{ state.name + " " + state.last_name | truncate }}
            </span>
          </ng-template>
        </p-dropdown>
      </td>
      <td
        style="width: 10%"
        class="text-center"
        *ngIf="this.rol !== 'Administrador'"
      >
        <p [ngClass]="getStatusClass(claim.status)">
          {{ claim.status | claimStatusDisplay }}
        </p>
      </td>
      <td style="width: 10%" *ngIf="this.rol == 'Administrador'">
        <p
          *ngIf="
            claim.status !== 'EnTramiteInterno' &&
            claim.status !== 'AudienciaFijada'
          "
          [ngClass]="getStatusClass(claim.status)"
        >
          {{ claim.status | claimStatusDisplay }}
        </p>
        <p-dropdown
          *ngIf="
            claim.status == 'EnTramiteInterno' ||
            claim.status == 'AudienciaFijada'
          "
          [options]="status"
          [ngClass]="
            getStatusClassForPrincipalView(claim.status | claimStatusDisplay)
          "
          class="status-dropdown"
          [placeholder]="claim.status | claimStatusDisplay"
          appendTo="body"
        >
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <span [ngClass]="getStatusClass(selectedOption ?? claim.status)"
              >{{ claim.status | claimStatusDisplay }}
            </span>
          </ng-template>
          <ng-template let-state pTemplate="item">
            <span class="px-3" [ngClass]="getStatusClass(state)"
              >{{ state | claimStatusDisplay }}
            </span>
          </ng-template>
        </p-dropdown>
      </td>
      <td class="text-center">
        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        <button
          type="button"
          pButton
          icon="pi pi-ellipsis-v"
          (click)="menu.toggle($event); selectClaim(claim._id)"
          class="p-button-rounded p-button-text"
          style="width: 2.5rem"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="10">
        <div class="flex flex-column align-items-center">
          <img
            src="../../../../../../assets/img/main/file-searching.svg"
            alt="imagen de archivo"
            style="width: 200px; height: 250px"
          />
          <p>No se encontraron resultados para la busqueda</p>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-paginator
  *ngIf="!homeView || !pagination.totalQuantity"
  (onPageChange)="changePage($event)"
  [first]="(pagination.actualPage - 1) * pagination.itemsPerPage"
  [rows]="pagination.itemsPerPage"
  [totalRecords]="pagination.totalQuantity"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
>
</p-paginator>

<!-- audience -->
<!-- <p-dialog
  header="Fijar Audiencia"
  [(visible)]="audienciaDialogVisible"
  [modal]="true"
  [closable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [draggable]="false"
>
  <app-audience
    (closePopup)="cancel($event)"
    (createAudienceOk)="createAudienceOk($event)"
    [claim]="claimSelected"
    (showMessage)="handleShowMessage($event)"
  ></app-audience>
</p-dialog> -->

<p-toast />
