import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ClaimCollectiveEntity } from "../entities/claim-colective.entity";
import { ClaimColectiveRepositoryBase } from "../repositories/cliam-colective-base.repository";

@Injectable()
export class CreateClaimColectiveUseCase implements UseCase<ClaimCollectiveEntity, string | Failure> {
    constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

    async execute(param: ClaimCollectiveEntity): Promise<string | Failure> {
        console.log(param);
        
        return await this.claimRepository.createClaim(param);
    }
}
