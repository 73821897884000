import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { CreateAudienceUseCase } from '../../../domain/usecases/create-audience.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Router } from '@angular/router';
import { CreateAudienceModel } from '../../../data/models/create-audience.model';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AudienceComponent implements OnChanges {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @Output() createAudienceOk: EventEmitter<boolean> = new EventEmitter();
  @Output() showMessage = new EventEmitter<{
    severity: string;
    summary: string;
    detail: string;
  }>();

  @Input() claim?: Partial<ClaimEntity> & {
    conciliator_full_name?: string;
  }  & {
    userId?: string;
  };

  time: Date[] | undefined;
  private AES = new AESEncryptDecryptService();
  minDate: Date = new Date();
  isProcessing: boolean = false;
  recargada: boolean = false;

  conciliatorFullName?: string;

  audienceForm: FormGroup = this.formBuilder.group({
    date: [, [Validators.required]],
    time: [, [Validators.required]],
    audienceTitle: [, [Validators.required, Validators.maxLength(30)]],
    conciliatorFullName: [, [Validators.required]],
    linkGoogleMeet: [, [Validators.required]],
    linkGoogleCalendar: [],
  });

  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageServiceBase,
    private createAudienceUseCase: CreateAudienceUseCase,
    private messageService: MessageService,
    private router: Router,
    private calendarTranslationService: CalendarTranslationService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.calendarTranslationService.configureCalendarTranslations();
    this.initializeFormWithClaimData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['claim']?.currentValue) {
      this.initializeFormWithClaimData();
    }
    this.calendarTranslationService.configureCalendarTranslations();
  }

  cancel() {
    const conciliatorName = this.audienceForm.get('conciliatorFullName')?.value;
    this.audienceForm.reset();
    
    this.audienceForm.patchValue({
      conciliatorFullName: conciliatorName
    });
    
    this.closePopup.emit(true);
  }

  initializeFormWithClaimData(): void {
    this.audienceForm.patchValue({
      conciliatorFullName: this.claim?.conciliatorFullName ? this.claim.conciliatorFullName
        : this.claim?.conciliator_full_name
        ? this.claim.conciliator_full_name
        : this.claim?.conciliator_full_name
        ? this.claim.conciliator_full_name
        : 'No asignado',
    });
  }

  async createAudience(): Promise<void> {
    this.isProcessing = true;
    this.audienceForm.disable();
    const result = await this.createAudienceUseCase.execute(
      this.createNewAudience()
    );
    if (result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      this.isProcessing = false;
      this.audienceForm.enable();
      return;
    }
    this.showMessage.emit({
      severity: 'success',
      summary: 'Audiencia creada',
      detail: 'La audiencia se creó exitosamente.',
    });
    this.createAudienceOk.emit(true);
    this.isProcessing = false;
    this.cancel();
  }

  createNewAudience(): CreateAudienceModel {
    const selectedDate = this.audienceForm.get('date')?.value;
    const selectedTime = this.audienceForm.get('time')?.value;
    const fullDate = new Date(selectedDate);
    fullDate.setHours(selectedTime.getHours() - 3);
    fullDate.setMinutes(selectedTime.getMinutes());
    fullDate.setSeconds(0);

    return new CreateAudienceModel(
      fullDate.toISOString(),
      new Date(selectedTime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      this.audienceForm.get('audienceTitle')?.value,
      this.claim?.id!,
      this.audienceForm.get('linkGoogleMeet')?.value,
      this.claim?.user_id! ? this.claim?.user_id : this.claim?.userId,
    );
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.audienceForm.get(controlName)?.hasError(errorType) &&
      this.audienceForm.get(controlName)?.touched
    );
  }

  openGoogleCalendar() {
    const title = 'Nombre del Evento';
    const description = 'Descripción del evento';
    const location = 'Ubicación del evento';
    const startDateObj = new Date();
    const endDateObj = new Date(startDateObj.getTime() + 60 * 60 * 1000);
    const startDate = this.formatDateForGoogleCalendar(startDateObj);
    const endDate = this.formatDateForGoogleCalendar(endDateObj);
    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title
    )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(
      description
    )}&location=${encodeURIComponent(location)}&sf=true&output=xml`;
    window.open(calendarUrl, '_blank');
  }

  formatDateForGoogleCalendar(date: Date): string {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  }
}
