import { Failure } from 'src/app/core/utils/failure';
import { ClaimCollectiveEntity } from '../entities/claim-colective.entity';
import { ClaimMultiEntity } from '../entities/claim-multi.entity';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { MultiPagination } from '../entities/multi-pagination.entity';

export abstract class ClaimMultiRepositoryBase {
  abstract createClaim(claim: ClaimMultiEntity): Promise<string | Failure>;
  abstract getMultiClaims(
    params: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure>;
}
